@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global */

body {
  margin: 0;
  font-family: "Chillax";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ededed;
}

::selection {
  color: white;
  background: #baf2bb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cardExperimental {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.cardExperimental.expanded {
  transform: translateX(-50%);
  grid-column: 1 / span 2;
}

.cardExperimental.expanded {
  transform: translateX(-50%);
  grid-column: 1 / span 2;
}

.placeholder-text {
  height: 0.8em;
  width: 80%;
  margin-top: 1.2em;
  background-color: #e2e8f0;
  opacity: 0.4;
}

@media (min-width: 768px) {
  .cardExperimental.expanded {
    transform: translateX(50%);
    grid-column: 3 / span 2;
  }
}

.unclalmp {
  overflow: hidden;
  cursor: pointer;
}

.unclamp:onclick {
  overflow: visible;
}
.slide-out {
  transform: translateY(-50px);
}

@media screen and (max-width: 768px) {
  .language-container {
    text-align: center;
  }

  .language-icon {
    font-size: 3rem; /* Adjust the icon size for mobile */
  }

  .language-text {
    display: block; /* Show the text always on mobile */
    font-size: 1.5rem; /* Adjust the text size for mobile */
  }
}

/* Start of: _Home page */
/* Animations */
.card:hover {
  transform: translateY(-7px);
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

.card:hover .icon-animation {
  transform: translateX(6px);
}

.postLink:hover .bounceEffect {
  animation: bounce 2s infinite;
}

.slideEffect {
  animation: slide 2s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-7px);
  }
}
@keyframes slide {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-7px);
  }
}

/* Home Language SVG Animations */
.text-grow-on-hover {
  transition: transform 0.2s ease-in-out;
}

.text-grow-on-hover:hover {
  transform: scale(1.1);
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}

.language-container {
  position: relative;
}

.language-text {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.language-container:hover .language-text {
  opacity: 1;
  bottom: -20px;
}

/* End of: _Home Page */

/* Start of: _Projects Page */

.author-grow-on-hover {
  transition: transform 0.2s ease-in-out;
}

.author-grow-on-hover:hover {
  transform: scale(1.1);
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}

.author-container {
  position: relative;
}

.author-text {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  white-space: nowrap;
}

.author-container:hover .author-text {
  opacity: 1;
  bottom: -20px;
}

/* End of: _Projects Page */

/* About page */
.half-round {
  border-radius: 200px 200px 0 0;
}

@keyframes backgroundAnim {
  0%,
  100% {
    background: linear-gradient(45deg, #83a4d4, #b6fbff);
  }
  25% {
    background: linear-gradient(45deg, #b6fbff, #83a4d4);
  }
  50% {
    background: linear-gradient(45deg, #91eae4, #86a8e7);
  }
  75% {
    background: linear-gradient(45deg, #86a8e7, #91eae4);
  }
}

.hdrAnim {
  animation: backgroundAnim 3600s ease infinite;
  background-size: 400% 400%;
  -webkit-animation: backgroundAnim 3600s ease infinite;
  -moz-animation: backgroundAnim 3600s ease infinite;
  -o-animation: backgroundAnim 3600s ease infinite;
  animation: backgroundAnim 3600s ease infinite;
}

#navbar {
  position: fixed;
  top: 0;
  transition: background-color 0.3s;
  z-index: 1000;
}

#navbar.scrolled {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
}

.project-card {
  background-color: rgba(254, 255, 234, 0.45);
  backdrop-filter: blur(10px);
}
.project-card:hover {
  background-color: rgba(246, 246, 236, 0.45);
  backdrop-filter: blur(10px);
}
